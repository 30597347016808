import React from "react";

export default function LogoSvg() {
  const logo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="190"
      height="31"
      viewBox="0 0 190 31"
      fill="none"
      className="logo-svg"
    >
      <path d="M2.67444 7.26106V25.1327C2.67444 26.2904 2.17089 26.8412 0.671408 26.8412C0.458796 26.8412 0.268563 26.8299 0 26.7962V30.9663C0.402845 30.9888 0.716169 31 1.10782 31C5.39365 31 8.14642 29.5163 8.14642 25.2451V7.26106H2.67444ZM5.40484 5.24909C6.96027 5.24909 8.21356 4.06889 8.21356 2.61893C8.21356 1.16896 6.96027 0 5.40484 0C3.8606 0 2.59611 1.16896 2.59611 2.61893C2.59611 4.06889 3.8606 5.24909 5.40484 5.24909Z" />
      <path d="M16.4747 24.818C18.8694 24.818 20.5255 23.8851 21.4655 22.1204H21.5998V24.5257H26.7472V12.7912C26.7472 9.13814 23.5133 7.03626 19.1379 7.03626C14.5164 7.03626 11.8979 9.37418 11.4615 12.5214L16.5083 12.7012C16.7432 11.5997 17.6496 10.9253 19.0932 10.9253C20.436 10.9253 21.2864 11.5772 21.2864 12.735V12.7912C21.2864 13.8477 20.145 14.0725 17.2132 14.331C13.7331 14.6233 10.8237 15.9159 10.8237 19.7375C10.8237 23.1545 13.1848 24.818 16.4747 24.818ZM18.1644 21.2212C16.8999 21.2212 16.0047 20.6142 16.0047 19.4677C16.0047 18.355 16.8775 17.6806 18.433 17.4445C19.4513 17.2984 20.7046 17.0736 21.32 16.7476V18.3887C21.32 20.0747 19.9101 21.2212 18.1644 21.2212Z" />
      <path d="M35.5651 14.6795C35.5762 12.7125 36.7176 11.5435 38.4521 11.5435C40.1866 11.5435 41.2161 12.69 41.2049 14.6008V24.5257H46.6769V13.5218C46.6881 9.6327 44.3269 7.03626 40.7125 7.03626C38.1724 7.03626 36.2477 8.32886 35.4867 10.4307H35.2965V7.26106H30.0931V24.5257H35.5651V14.6795Z" />
      <path d="M54.91 24.818C57.3047 24.818 58.9608 23.8851 59.9008 22.1204H60.0351V24.5257H65.1826V12.7912C65.1826 9.13814 61.9486 7.03626 57.5733 7.03626C52.9517 7.03626 50.3332 9.37418 49.8968 12.5214L54.9436 12.7012C55.1786 11.5997 56.085 10.9253 57.5285 10.9253C58.8713 10.9253 59.7218 11.5772 59.7218 12.735V12.7912C59.7218 13.8477 58.5804 14.0725 55.6486 14.331C52.1684 14.6233 49.259 15.9159 49.259 19.7375C49.259 23.1545 51.6201 24.818 54.91 24.818ZM56.5997 21.2212C55.3352 21.2212 54.44 20.6142 54.44 19.4677C54.44 18.355 55.3129 17.6806 56.8683 17.4445C57.8866 17.2984 59.1399 17.0736 59.7553 16.7476V18.3887C59.7553 20.0747 58.3454 21.2212 56.5997 21.2212Z" />
      <path d="M68.5284 24.5257H74.0004V15.1516C74.0004 13.0946 75.4551 11.7121 77.4134 11.7121C78.0624 11.7121 79.0359 11.8245 79.5619 12.0044V7.22734C79.1031 7.1037 78.5324 7.02502 78.0288 7.02502C76.1489 7.02502 74.6494 8.12654 74.0228 10.4083H73.8437V7.26106H68.5284V24.5257Z" />
      <path d="M88.9224 24.8517C94.3273 24.8517 97.606 21.2661 97.606 15.9496C97.606 10.6218 94.3273 7.03626 88.9224 7.03626C83.5176 7.03626 80.2389 10.6218 80.2389 15.9496C80.2389 21.2661 83.5176 24.8517 88.9224 24.8517ZM88.956 20.7041C86.9306 20.7041 85.8116 18.7708 85.8116 15.9159C85.8116 13.0497 86.9306 11.1051 88.956 11.1051C90.9143 11.1051 92.0333 13.0497 92.0333 15.9159C92.0333 18.7708 90.9143 20.7041 88.956 20.7041Z" />
      <path d="M115.415 12.5326C115.124 9.11566 112.338 7.03626 107.683 7.03626C103.028 7.03626 100.062 9.0707 100.085 12.5326C100.062 15.1853 101.752 16.8938 105.187 17.5344L108.186 18.1077C109.607 18.3887 110.256 18.8383 110.279 19.6026C110.256 20.4681 109.294 21.0413 107.895 21.0413C106.34 21.0413 105.288 20.3669 105.053 19.108L99.6482 19.2429C100.04 22.6599 102.96 24.8517 107.873 24.8517C112.472 24.8517 115.818 22.5475 115.84 18.9956C115.818 16.4779 114.162 14.983 110.738 14.3198L107.425 13.6904C105.971 13.3981 105.501 12.8811 105.512 12.2179C105.501 11.3412 106.519 10.8017 107.783 10.8017C109.227 10.8017 110.234 11.5772 110.391 12.6675L115.415 12.5326Z" />
      <path d="M118.534 24.5257H124.006V14.3086C124.006 12.6001 125.002 11.5098 126.435 11.5098C127.856 11.5098 128.796 12.5102 128.796 14.1062V24.5257H134.055V14.2186C134.055 12.5664 134.961 11.5098 136.472 11.5098C137.86 11.5098 138.844 12.4427 138.844 14.1624V24.5257H144.316V12.9036C144.316 9.25054 142.078 7.03626 138.833 7.03626C136.293 7.03626 134.29 8.37382 133.652 10.4307H133.473C133.014 8.36258 131.168 7.03626 128.784 7.03626C126.435 7.03626 124.644 8.32886 123.928 10.4307H123.738V7.26106H118.534V24.5257Z" />
      <path d="M155.73 24.8517C161.135 24.8517 164.414 21.2661 164.414 15.9496C164.414 10.6218 161.135 7.03626 155.73 7.03626C150.326 7.03626 147.047 10.6218 147.047 15.9496C147.047 21.2661 150.326 24.8517 155.73 24.8517ZM155.764 20.7041C153.739 20.7041 152.619 18.7708 152.619 15.9159C152.619 13.0497 153.739 11.1051 155.764 11.1051C157.722 11.1051 158.841 13.0497 158.841 15.9159C158.841 18.7708 157.722 20.7041 155.764 20.7041Z" />
      <rect x="167.242" y="19.2504" width="22.7583" height="5.27532" />
    </svg>
  );

  return logo;
}
